/* eslint-disable indent */
import React from "react";
import { Menu, MenuProps } from "antd";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useLocation, useNavigate } from "react-router-dom";
import "./Navbar.css";
import {
  // AppstoreOutlined,
  CarOutlined,
  CreditCardOutlined,
  HddOutlined,
  IdcardOutlined,
  SafetyCertificateOutlined,
  SwapOutlined,
  FileTextOutlined,
  MobileOutlined,
  // BankOutlined,
  SecurityScanOutlined,
} from "@ant-design/icons";
import { setSelectedBusinessLabel, setSelectedLabel } from "store/slices/backgroundCheck.slice";
import {
  BusinessVerificationSvgIcon,
  IdentityVerificationSvgIcon,
  LegalVerificationSvgIcon,
  PanListSvgIcon,
  TeamsSvgIcon,
  // RupeeSvgIcon,
} from "Icon/Icon";

const Navbar = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.userDetails);
  // const selectedBackgroundKey = useAppSelector((state) => state.backgroundCheck.selectedKey);
  const dispatch = useAppDispatch();
  //   const availableCredit = useAppSelector((state) => state.auth.availableCredit);
  //   const dispatch = useAppDispatch();
  const selectedReduxLabel = useAppSelector((state) => state.backgroundCheck.selectedLabel);
  const selectedBusinessLabel = useAppSelector(
    (state) => state.backgroundCheck.selectedBusinessLabel
  );
  const location = useLocation();
  const path = location.pathname;
  const segments = path.split("/").filter(Boolean);
  const activePath = segments.length > 0 ? `/${segments[0]}` : "/";
  const activeSubTab =
    activePath === "/add-background-verification"
      ? selectedReduxLabel
      : activePath === "/add-business-verification"
      ? selectedBusinessLabel
      : activePath.startsWith("/bulk-verification")
      ? path
      : activePath === "/legal-verification"
      ? "/"
      : activePath;
  const menuItems: MenuProps["items"] = [
    {
      key: "/",
      icon: <LegalVerificationSvgIcon />,
      label: "Legal Verification",
      onClick: () => navigate("/"),
    },
    {
      key: "/full-background-verification",
      icon: <SecurityScanOutlined style={{ fontSize: "20px" }} />,
      label: "Full Background Verification",
      onClick: () => navigate("/full-background-verification"),
    },
    {
      key: "/add-background-verification",
      icon: <IdentityVerificationSvgIcon />,
      label: "Background Verification",
      onClick: () => navigate("/add-background-verification"),
      children: [
        {
          key: "PAN",
          icon: <CreditCardOutlined />,
          label: "PAN",
          onClick: () => {
            dispatch(setSelectedLabel("PAN"));
          },
        },
        {
          key: "Aadhaar",
          icon: <IdcardOutlined />,
          label: "Aadhaar",
          onClick: () => {
            dispatch(setSelectedLabel("Aadhaar"));
          },
        },
        {
          key: "Voter",
          icon: <SafetyCertificateOutlined />,
          label: "Voter",
          onClick: () => {
            dispatch(setSelectedLabel("Voter"));
          },
        },
        // { key: "4", icon: <BankOutlined />, label: "Passport" },
        {
          key: "Driving License",
          icon: <CarOutlined />,
          label: "Driving License",
          onClick: () => {
            dispatch(setSelectedLabel("Driving License"));
          },
        },
        {
          key: "RC Number",
          icon: <IdcardOutlined />,
          label: "RC Number",
          onClick: () => {
            dispatch(setSelectedLabel("RC Number"));
          },
        },
        // { key: "7", icon: <UserOutlined />, label: "UAN" },
        {
          key: "Credit Score",
          icon: <CreditCardOutlined />,
          label: "Credit Score",
          onClick: () => {
            dispatch(setSelectedLabel("Credit Score"));
          },
        },
        {
          key: "Mobile to UAN",
          icon: <MobileOutlined />,
          label: "Mobile to UAN",
          onClick: () => {
            dispatch(setSelectedLabel("Mobile to UAN"));
          },
        },
        {
          key: "EPFO",
          icon: <MobileOutlined />,
          label: "EPFO Verification",
          onClick: () => {
            dispatch(setSelectedLabel("EPFO"));
          },
        },
      ],
    },
    {
      key: "/add-business-verification",
      icon: <BusinessVerificationSvgIcon />,
      label: "Business Verification",
      onClick: () => navigate("/add-business-verification"),
      children: [
        {
          key: "GST Verification",
          icon: <CreditCardOutlined />,
          label: "GST Verification",
          onClick: () => {
            dispatch(setSelectedBusinessLabel("GST Verification"));
          },
        },
        {
          key: "Company Verification (MCA 21)",
          icon: <IdcardOutlined />,
          label: "Company Verification",
          onClick: () => {
            dispatch(setSelectedBusinessLabel("Company Verification (MCA 21)"));
          },
        },
        // { key: "3", icon: <SafetyCertificateOutlined />, label: "TAN Verification" },
        // { key: "4", icon: <BankOutlined />, label: "PAN From DIN" },
        {
          key: "Udyam Verification",
          icon: <HddOutlined />,
          label: "Udyam Verification",
          onClick: () => {
            dispatch(setSelectedBusinessLabel("Udyam Verification"));
          },
        },
        {
          key: "Mobile to Udyam",
          icon: <MobileOutlined />,
          label: "Mobile to Udyam",
          onClick: () => {
            dispatch(setSelectedBusinessLabel("Mobile to Udyam"));
          },
        },
        {
          key: "PAN to Udyam",
          icon: <FileTextOutlined />,
          label: "PAN to Udyam",
          onClick: () => {
            dispatch(setSelectedBusinessLabel("PAN to Udyam"));
          },
        },
      ],
    },
    {
      key: "/bulk-verification",
      icon: <PanListSvgIcon />,
      label: "Bulk Verification",
      children: [
        {
          key: "/bulk-verification/bulk-pan-verification",
          icon: <CreditCardOutlined />,
          label: "PAN Verification",
          onClick: () => navigate("/bulk-verification/bulk-pan-verification"),
        },
        {
          key: "/bulk-verification/bulk-pan-to-udyam",
          icon: <CreditCardOutlined />,
          label: "PAN to Udyam ",
          onClick: () => navigate("/bulk-verification/bulk-pan-to-udyam"),
        },
        {
          key: "/bulk-verification/bulk-mobile-to-udyam",
          icon: <CreditCardOutlined />,
          label: "Mobile to Udyam ",
          onClick: () => navigate("/bulk-verification/bulk-mobile-to-udyam"),
        },
      ],
    },
    {
      key: "/teams",
      icon: <TeamsSvgIcon />,
      label: "Teams",
      onClick: () => navigate("/teams"),
    },
    // {
    //   key: "/order-list",
    //   icon: <RupeeSvgIcon />,
    //   label: "Recharge",
    //   onClick: () => navigate("/order-list"),
    // },
    {
      key: "/transaction-history",
      icon: <SwapOutlined style={{ fontSize: "24px" }} />,
      label: "Transaction History",
      onClick: () => navigate("/transaction-history"),
    },
  ];

  const filteredMenuItems = (menuItems || []).filter((item) => {
    if (!item) return false;
    if (
      (item.key === "/teams" || item.key === "/order-list") &&
      user?.role?.toLowerCase() !== "admin"
    ) {
      return false;
    }
    return true;
  });
  return (
    <Menu
      className="header-menu"
      theme="dark"
      mode="inline"
      selectedKeys={[activeSubTab]}
      // openKeys={[selectedBackgroundKey]}
      items={filteredMenuItems}
    />
  );
};

export default Navbar;
