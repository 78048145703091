import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "pages/LoginPage";
import { withLoading } from "hocs/withLoading.hoc";
import RequireAuth from "./RequireAuth";
// import MainLayout from "layouts/MainLayout/MainLayout";
import { Register } from "components/Register/Register";
import { VerifyEmail } from "components/VerifyEmail/VerifyEmail";
import AddTeamMember from "pages/Teams/AddTeamMember";
import BussinessDashboard from "components/Dashboard/BussinessDashboard";
import ForgotPasswordPage from "pages/ForgotPasswordPage";
import ResetPasswordPage from "pages/ResetPasswordPage";
import NewLayout from "NewUI/NewLayout";
import { ConfigProvider } from "antd";
import NotFoundPage from "pages/NotFoundPage";
// import FullBackgroundResponsePage from "pages/FullBackgroundResponsePage";

export const DASHBOARD_PATH = "/";

const AuthLayoutFallback = React.lazy(() => import("layouts/AuthLayout/AuthLayout"));
const DashboardPage = React.lazy(() => import("pages/DashboardPage"));
const PANToUdyamBulkVerificationPage = React.lazy(
  () => import("pages/PANToUdyamBulkVerificationPage")
);
const MobileToUdyamBulkVerificationPage = React.lazy(
  () => import("pages/MobileToUdyamBulkVerificationPage")
);
const RecordListPage = React.lazy(() => import("pages/RecordListPage"));
const DetailsRecordListPage = React.lazy(() => import("pages/DetailsRecordListPage"));
const AddRecordPage = React.lazy(() => import("pages/AddRecordPage"));
const AddBulkPANToUdyamRecordPage = React.lazy(() => import("pages/AddBulkPANToUdyamRecordPage"));
const RecordListBulkPANToUdyamPage = React.lazy(() => import("pages/RecordListBulkPANToUdyamPage"));
const DetailsRecordListBulkPANToUdyamPage = React.lazy(
  () => import("pages/DetailsRecordListBulkPANToUdyamPage")
);
const AddBulkMobileToUdyamRecordPage = React.lazy(
  () => import("pages/AddBulkMobileToUdyamRecordPage")
);
const RecordListBulkMobileToUdyamPage = React.lazy(
  () => import("pages/RecordListBulkMobileToUdyamPage")
);
const DetailsRecordListBulkMobileToUdyamPage = React.lazy(
  () => import("pages/DetailsRecordListBulkMobileToUdyamPage")
);
const VericationPage = React.lazy(() => import("pages/VerificationPage"));
const BusinessVerificationPage = React.lazy(() => import("pages/BusinessVerificationPage"));
const LegalVerificationPage = React.lazy(() => import("pages/LegalVerificationPage"));
const LegalListPage = React.lazy(() => import("pages/LegalListPage"));
const LegalResponsePage = React.lazy(() => import("pages/LegalResponsePage"));

// const RechargePage = React.lazy(() => import("pages/RechargePage"));
// const OrderListPage = React.lazy(() => import("pages/OrderListPage"));
const TeamsPage = React.lazy(() => import("pages/Teams/TeamsPage"));
const ProfilePage = React.lazy(() => import("pages/ProfilePage"));
const TransactionHistoryPage = React.lazy(() => import("pages/TransactionHistoryPage"));
const FullBackgroundVerificationPage = React.lazy(
  () => import("pages/FullBackgroundVerificationPage")
);
const FullBackgroundResponsePage = React.lazy(() => import("pages/FullBackgroundResponsePage"));
const AddFullBackgroundVerificationPage = React.lazy(
  () => import("pages/AddFullBackgroundVerificationPage")
);

const Logout = React.lazy(() => import("./Logout"));
const Dashboard = withLoading(DashboardPage);
const PANToUdyamBulkVerification = withLoading(PANToUdyamBulkVerificationPage);
const MobileToUdyamBulkVerification = withLoading(MobileToUdyamBulkVerificationPage);
const RecordList = withLoading(RecordListPage);
const DetailsRecordList = withLoading(DetailsRecordListPage);
const AddRecord = withLoading(AddRecordPage);
const AddBulkPANToUdyamRecord = withLoading(AddBulkPANToUdyamRecordPage);
const RecordListBulkPANToUdyam = withLoading(RecordListBulkPANToUdyamPage);
const DetailsRecordListBulkPANToUdyam = withLoading(DetailsRecordListBulkPANToUdyamPage);
const AddBulkMobileToUdyamRecord = withLoading(AddBulkMobileToUdyamRecordPage);
const RecordListBulkMobileToUdyam = withLoading(RecordListBulkMobileToUdyamPage);
const DetailsRecordListBulkMobileToUdyam = withLoading(DetailsRecordListBulkMobileToUdyamPage);
// const Recharge = withLoading(RechargePage);
// const OrderList = withLoading(OrderListPage);
const Teams = withLoading(TeamsPage);
const AddTeams = withLoading(AddTeamMember);
const Profile = withLoading(ProfilePage);
const LogoutFallback = withLoading(Logout);
const Verification = withLoading(VericationPage);
const BusinessVerification = withLoading(BusinessVerificationPage);
const LegalVerification = withLoading(LegalVerificationPage);
const LegalList = withLoading(LegalListPage);
const LegalResponse = withLoading(LegalResponsePage);
const TransactionHistory = withLoading(TransactionHistoryPage);
const FullBackgroundVerification = withLoading(FullBackgroundVerificationPage);
const FullBackgroundResponse = withLoading(FullBackgroundResponsePage);
const AddFullBackgroundVerification = withLoading(AddFullBackgroundVerificationPage);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <NewLayout />
    </RequireAuth>
  );

  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: "#AC3CE1",
        },
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path={DASHBOARD_PATH} element={protectedLayout}>
            <Route index element={<LegalList />} />
            <Route path="bulk-verification">
              {/* Bulk PAN Verification*/}
              <Route path="bulk-pan-verification" element={<Dashboard />} />
              <Route path="bulk-pan-verification/record/:id" element={<RecordList />} />
              <Route
                path="/bulk-verification/bulk-pan-verification/details/:id"
                element={<DetailsRecordList />}
              />
              <Route path="bulk-pan-verification/add-record" element={<AddRecord />} />
              {/* Bulk PAN to Udyam */}
              <Route path="bulk-pan-to-udyam" element={<PANToUdyamBulkVerification />} />
              <Route path="bulk-pan-to-udyam/add-record" element={<AddBulkPANToUdyamRecord />} />
              <Route path="bulk-pan-to-udyam/record/:id" element={<RecordListBulkPANToUdyam />} />
              <Route
                path="/bulk-verification/bulk-pan-to-udyam/details/:id"
                element={<DetailsRecordListBulkPANToUdyam />}
              />{" "}
              {/* Bulk Mobile to Udyam */}
              <Route path="bulk-mobile-to-udyam" element={<MobileToUdyamBulkVerification />} />
              <Route
                path="bulk-mobile-to-udyam/add-record"
                element={<AddBulkMobileToUdyamRecord />}
              />
              <Route
                path="bulk-mobile-to-udyam/record/:id"
                element={<RecordListBulkMobileToUdyam />}
              />
              <Route
                path="/bulk-verification/bulk-mobile-to-udyam/details/:id"
                element={<DetailsRecordListBulkMobileToUdyam />}
              />
            </Route>

            <Route path="add-background-verification" element={<Verification />} />
            <Route path="add-business-verification" element={<BusinessVerification />} />
            <Route
              path="legal-verification/add-legal-verification"
              element={<LegalVerification />}
            />
            <Route path="legal-verification" element={<LegalList />} />
            <Route path="legal-verification/:id" element={<LegalResponse />} />
            {/* <Route path="order-list/recharge" element={<Recharge />} /> */}
            {/* <Route path="order-list" element={<OrderList />} /> */}
            <Route path="teams" element={<Teams />} />
            <Route path="profile" element={<Profile />} />
            <Route path="teams/add-teams" element={<AddTeams />} />
            <Route path="bussiness-dashboard" element={<BussinessDashboard />} />
            <Route path="transaction-history" element={<TransactionHistory />} />
            <Route path="full-background-verification" element={<FullBackgroundVerification />} />
            <Route path="full-background-verification/:id" element={<FullBackgroundResponse />} />
            <Route
              path="full-background-verification/add-full-background-verification"
              element={<AddFullBackgroundVerification />}
            />
          </Route>
          <Route path="/auth" element={<AuthLayoutFallback />}>
            <Route path="login" element={<LoginPage />} />
            <Route path="forgot-password" element={<ForgotPasswordPage />} />
            <Route path="reset-password" element={<ResetPasswordPage />} />
            <Route path="register" element={<Register />} />
            <Route path="verify" element={<VerifyEmail />} />
          </Route>
          <Route path="/logout" element={<LogoutFallback />} />
          {/* <Route path="/new-ui" element={<NewLayout />} /> */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
};
