import { notificationController } from "components/Common/Notification/Notification";
import { useAppSelector } from "hooks/reduxHooks";

export const camelize = (string: string): string => {
  return string
    .split(" ")
    .map((word, index) =>
      index === 0 ? word.toLowerCase() : word[0].toUpperCase() + word.slice(1)
    )
    .join("");
};

export const getDate = (date: string): string => {
  if (!date) return "";
  return date.split("T")[0];
};

export const getCurrencyPrice = (price: number, currency: string): string => {
  switch (currency) {
    case "USD": {
      return `$${price}`;
    }

    default: {
      return `$${price}`;
    }
  }
};

export const parseJwt = (token: string) => {
  if (!token) {
    return;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

export const capitalize = (word: string): string =>
  `${word[0].toUpperCase()}${word.slice(1)}`;

export const trimStr = (str: string, charCount = 15) => {
  if (!str) return "";
  const res = str.substring(0, charCount);
  return res + "...";
};

export const isMorePages = (dataLength: number, perPageRecord = 10) => {
  dataLength = dataLength ? dataLength : 0;
  return dataLength > perPageRecord ? true : false;
};

export const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

export const validateObj: any = {
  aadhaar: {
    regEx: /^\d{12}$/,
    maxlength: 10,
  },
  pan: {
    regEx: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
  },
  pan_allin_one: {
    regEx: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
  },
  credit_score: {
    regEx: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
  },
  voter: {
    regEx: /^[A-Z]{3}[0-9]{7}$/,
  },
  gst: {
    regEx: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
  },
  tan: {
    regEx: /[A-Z]{4}[0-9]{5}[A-Z]{1}/,
  },
  udyam_registration_verification: {
    regEx: /^UDYAM-[A-Z]{2}-\d{2}-\d{7}$/,
  },
  din: {
    regEx: /^\d{8}$/,
  },
  dl: {
    regEx:
      /^(([A-Z]{2}[0-9]{2})|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/,
  },
  passport: {
    regEx: /^[A-Z0-9]+$/,
  },
  company_name: {
    regEx: /^[LU][0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/,
  },
  rc: {
    regEx: /^[A-Z|a-z]{2}\s?[0-9]{1,2}\s?[A-Z|a-z]{0,3}\s?[0-9]{4}$/,
  },
  uan: {
    regEx: /^[0-9]{10}$/,
  },
  pinCode: {
    regEx: /^[1-9][0-9]{5}$/,
  },
  mobile: {
    regEx: /^[6-9]\d{9}$/,
  },
};

export function getAccountType(accountTypeCode) {
  const accountTypes = {
    0: "Auto Loan",
    1: "Unsecured Loan",
    2: "Secured Loan",
    3: "Partially Secured Loan",
    4: "Home Improvement Loan",
    5: "FHA Home Improvement Loan",
    6: "Installment Sales Contract",
    7: "Charge Account",
    10: "Business Loan",
    11: "Recreational Merchandise Loan",
    12: "Educational Loan",
    13: "Lease (Non-auto)",
    15: "Line of Credit",
    17: "Manufactured Housing Loan",
    18: "Credit Card",
    19: "FHA Real Estate Mortgage",
    20: "Note Loan",
    22: "Secured by Household Goods",
    23: "Secured by Household Goods and Other Collateral",
    25: "VA Real Estate Mortgage Loan",
    26: "Conventional Real Estate Mortgage",
    29: "Rental Agreement",
    34: "Debt Counseling Service",
    37: "Combined Credit Plan",
    43: "Debit Card",
    47: "Credit Line Secured",
    48: "Collection Agency/Attorney",
    50: "Family Support",
    65: "Government Unsecured Guaranteed Loan",
    66: "Government Secured Guaranteed Loan",
    67: "Government Unsecured Direct Loan",
    68: "Government Secured Direct Loan",
    69: "Government Grant",
    70: "Government Overpayment",
    71: "Government Fine",
    72: "Government Fee for Services",
    73: "Government Employee Advance",
    74: "Government Miscellaneous Debt",
    75: "Government Benefit",
    77: "Returned Check",
    89: "Home Equity Line of Credit",
    90: "Medical Debt",
    91: "Debt Consolidation",
    92: "Utility Company",
    93: "Child Support",
    95: "Attorney Fees",
  };

  return accountTypes[accountTypeCode] || "Unknown Account Type";
}

export function getAccountStatus(accountStatusCode) {
  const accountStatusMap = {
    0: "Active Account",
    1: "Current Account",
    2: "Closed Account",
    3: "Account in Collections",
    5: "Account Settled",
    7: "Account Repaid",
    8: "Written-Off Account",
    11: "Active Account",
    13: "Closed Account", // Added code 13
    93: "Delinquent or Charged-Off Account",
    94: "Account Defaulted",
    95: "Suit Filed or Willful Default",
  };

  return accountStatusMap[accountStatusCode] || "Unknown Account Status";
}

export function getPortfolioType(type) {
  const portfolioMap = {
    R: "Credit Card",
    M: "Morgage",
    I: "Installment Loan",
  };

  return portfolioMap[type] || "Unknown Portfolio";
}

export function getGender(type) {
  const portfolioMap = {
    1: "Male",
    2: "Female",
    3: "Other",
  };

  return portfolioMap[type] || "Unknown Portfolio";
}

export function calculatePercentage(part, whole) {
  if (whole === 0) {
    return undefined; // Return undefined for invalid cases
  }
  return (part / whole) * 100;
}

export function formatNumberWithCommas(number) {
  const formatter = new Intl.NumberFormat("en-IN");
  return formatter.format(number);
}

export const formatDate = (dateString) => {
  const date = new Date(dateString);

  const padZero = (num) => String(num).padStart(2, "0");

  return `${padZero(date.getDate())}/${padZero(
    date.getMonth() + 1
  )}/${date.getFullYear()} ${padZero(date.getHours())}:${padZero(
    date.getMinutes()
  )}:${padZero(date.getSeconds())}`;
};

/**
 * Calculate the final price including GST and round it up.
 * @param {number} basePrice - The base price of the service.
 * @param {number} gstRate - The GST rate in percentage (default: 18).
 * @returns {number} - The final rounded-up price.
 */

export function calculateAPIPriceWithGST(basePrice, gstRate = 18) {
  const gstAmount = (basePrice * gstRate) / 100;
  const finalPrice = Math.ceil(basePrice + gstAmount);
  return finalPrice;
}

export function useApiPrice(type: string) {
  const prices = useAppSelector((state) => state.auth.APIPrices);
  const apiPrice = prices.find(item => item.type === type);
  return apiPrice ? apiPrice.price : null; // Handle cases where the type isn't found
}

export const showSuccessNotification = (type: string, days = "1-2") => {
  notificationController.success({
    message: `Your ${type} results will be sent to your email within ${days} business days.`,
  });
};

